export const enum Color {
  PRIMARY_COLOR = '#F7B900',
  APPLE = '#34B340',
  MEDIUM_GREEN = '#35B43F',
  LIGHT_GREEN = '#E4F7E5',
  CELESTIAL_BLUE = '#548ED6',
  LIGHT_SHADE_BLUE = '#7879F1',
  BLUE = '#2774CD',
  DEEP_LILAC = '#9845C5',
  FANDANGO = '#B1387F',
  BACKGROUND_COLOR = '#F3E9E0',
  RAISIN_BLACK = '#2C201F',
  ERROR = '#FF5438',
  WHITE = '#FFFFFF',
  BLACK = '#000000',
  GREY = '#F3F3F3',
  SHADOW_GREY = '#F7F7F7',
  LIGHT_GREY = '#EEEEEE',
  TEXT_SECONDARY = '#616161',
  ACCENT = '#FFF7E4',
  BROWNISH_GREY_TWO = '#B0A099',
  BROWNISH_GREY = '#4A3532',
  BG_MAIN = '#F4E8DE',
}

export const enum Place {
  footer = 'footer',
  last_section = 'last_section',
}

export const enum ColorRGB {
  RAISIN_BLACK = '44, 32, 31',
  TEXT_SECONDARY = '97, 97, 97',
  WHITE = '255, 255, 255',
  BROWNISH_GREY_TWO = '176, 160, 153',
  PRIMARY_COLOR = '247, 185, 0',
}

export const enum ANALYTICS_PAGE {
  MAIN = 'main',
  ABOUT = 'about',
  EXPERTS = 'experts',
  BLOG = 'blog',
  PARTNERS_INFLUENCERS = 'partners_influencers',
  PARTNERS_BUSINESS = 'partners_business',
  CALCULATOR = 'calculator',
  CONTACT = 'contact',
  PRIVACY = 'privacy',
  TERMS = 'terms',
  COOKIE = 'cookie',
  CALCULATOR_1 = 'calculator_1',
  CALCULATOR_2 = 'calculator_2',
  CALCULATOR_3 = 'calculator_3',
  CALCULATOR_4 = 'calculator_4',
  CALCULATOR_5 = 'calculator_5',
  CALCULATOR_6 = 'calculator_6',
  CALCULATOR_7 = 'calculator_7',
  CALCULATOR_8 = 'calculator_8',
  AUTO_RENEWAL_AGREEMENT = 'auto-renewal-agreement',
  TERMS_CN = 'terms-of-use-cn',
}

export const enum PAGE_URL {
  MAIN = 'main',
  ABOUT = 'about',
  EXPERTS = 'experts',
  BLOG = 'blog',
  PARTNERS_INFLUENCERS = 'influencers',
  PARTNERS_BUSINESS = 'business',
  CONTACT = 'contact-us',
  PRIVACY = 'privacy-policy',
  TERMS = 'terms-of-use',
  TERMS_CN = 'terms-of-use-cn',
  COOKIE = 'cookie-policy',
  CALCULATOR = 'calculator',
  AUTO_RENEWAL_AGREEMENT = 'auto-renewal-agreement',
}

export const PAGES_MAP = {
  [PAGE_URL.ABOUT]: ANALYTICS_PAGE.ABOUT,
  [PAGE_URL.EXPERTS]: ANALYTICS_PAGE.EXPERTS,
  [PAGE_URL.BLOG]: ANALYTICS_PAGE.BLOG,
  [PAGE_URL.PARTNERS_INFLUENCERS]: ANALYTICS_PAGE.PARTNERS_INFLUENCERS,
  [PAGE_URL.PARTNERS_BUSINESS]: ANALYTICS_PAGE.PARTNERS_BUSINESS,
  [PAGE_URL.CONTACT]: ANALYTICS_PAGE.CONTACT,
  [PAGE_URL.TERMS]: ANALYTICS_PAGE.TERMS,
  [PAGE_URL.COOKIE]: ANALYTICS_PAGE.COOKIE,
  [PAGE_URL.PRIVACY]: ANALYTICS_PAGE.PRIVACY,
  [PAGE_URL.CALCULATOR]: ANALYTICS_PAGE.CALCULATOR,
}

export const enum SOCIAL_MEDIA {
  TIK_TOK = 'tik_tok',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
  FACEBOOK = 'facebook',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum FirebaseRemoteConfigKey {
  WEBSITE_AMPLITUDE_ANALYTICS = 'website_amplitude_analytics',
  WEBSITE_CONFIG_FILE = 'website_config_file',
}

export const defaultWebSiteConfigFile = {
  web_funnel_link: '',
}

export const enum FacebookPixelEvent {
  PAGE_VIEW = 'PageView',
  WEB_ONB_BTN_CLICKED = 'WebOnbBtnClicked',
  FORM_SUBMITTED = 'FormSubmitted',
}

export const MIN_WIDTH = 360
export const MAX_PHONE = 414
export const BASE_COLUMN_WIDTH = 1024
export const BLOG_DESKTOP_COLUMN_WIDTH = 760
export const TRANSITION_DURATION_IN_SECONDS = 0.3
export const CONTACT_US_TARGET_ELEMENT_ID = 'contact-us-target'
export const LOGGER_WAS_INITIALIZED = 'LOGGER_WAS_INITIALIZED'

export const CONTACT_FORM_LINK_PROD = 'https://account.woofz.com/contact-form'
export const CONTACT_FORM_LINK_DEV =
  'https://account-woofz.gidev.xyz/contact-form'

export const ENVS = {
  DEV: 'dev',
  PROD: 'prod',
}

export const WEBAPP_LINKS = {
  [ENVS.DEV]: 'https://app.supernove8.com?utm_source=woofzwebsite',
  [ENVS.PROD]: 'https://app.woofz.com?utm_source=woofzwebsite',
}
