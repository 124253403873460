import { initializeApp } from 'firebase/app'
import {
  fetchAndActivate,
  getRemoteConfig,
  getBoolean,
  getString,
} from 'firebase/remote-config'
import { FirebaseRemoteConfigKey } from '../../constants'
import { initEventLogger } from './initEventLogger'

const credentials = {
  apiKey: 'AIzaSyAb-GfwbkSuTRP9Uz1FK33fuh1k0ZAO9Mg',
  authDomain: 'woofz-dev.firebaseapp.com',
  projectId: 'woofz-dev',
  storageBucket: 'woofz-dev.appspot.com',
  messagingSenderId: '951503900193',
  appId: '1:951503900193:web:2324a8048966aa76de1d60',
  measurementId: 'G-MJ1T87TVSC',
}

export class FirebaseService {
  private remoteConfig: any
  private shouldSendAmplitudeEvent: boolean

  constructor() {
    this.shouldSendAmplitudeEvent = false
  }

  async init() {
    const app = initializeApp(credentials)
    const remoteConfig = getRemoteConfig(app)
    await fetchAndActivate(remoteConfig)

    this.remoteConfig = remoteConfig
    this.shouldSendAmplitudeEvent = getBoolean(
      this.remoteConfig,
      FirebaseRemoteConfigKey.WEBSITE_AMPLITUDE_ANALYTICS,
    )

    if (this.shouldSendAmplitudeEvent) {
      initEventLogger()
    }
  }

  public getAmplitudeFlag() {
    return this.shouldSendAmplitudeEvent
  }

  public getAmplitudeValueByKey(key: FirebaseRemoteConfigKey) {
    return this.remoteConfig ? getString(this.remoteConfig, key) : {}
  }
}

export const firebaseService = new FirebaseService()
